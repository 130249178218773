<template>
    <div>
        <v-card class="text-center pa-1" :loading="isLoading">
            <v-card-title class="justify-center display-1 mb-2">{{ $t('views.passport.provision.title') }}</v-card-title>
            <v-card-text>
                <v-btn v-if="provisionViewModel && provisionViewModel.account"
                    color="primary" x-large block elevation="2" class="mt-2"
                    :loading="isSubmitting"
                    @click="bindCurrentAccount()"
                >
                    <v-icon>mdi-account-star</v-icon>
                    {{ $t('views.passport.provision.associateWithCurrent', { name: provisionViewModel.account.name }) }}
                </v-btn>
                <v-btn
                    color="primary" x-large block elevation="2" class="mt-2"
                    :loading="isSubmitting"
                    @click="bindOtherAccount()"
                >
                    <v-icon>mdi-account-star</v-icon>
                    {{ $t('views.passport.provision.associateWithOther') }}
                </v-btn>
                <v-btn
                    color="secondary" x-large block elevation="2" class="mt-2"
                    :loading="isSubmitting"
                    @click="createAccount()"
                >
                    <v-icon>mdi-account-plus</v-icon>
                    {{ $t('views.passport.provision.createNewAccount') }}
                </v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import utils from '@/utils';
import { getProvisionViewModel, bindCurrentAccount } from '@/api';

export default {
    data () {
        return {
            isLoading: false,

            isSubmitting: false,

            /**
             * 当前登录账号的信息，如果当前账号有登录那么就获取账号信息，否则为null
             */
            provisionViewModel: null,

            /**
             * 登录类型,可选值 password / mobile / email
             */
            loginType: 'password',

            /**
             * 解析的视图模型
             */
            passportViewModel: null
        };
    },
    methods: {
        async bindCurrentAccount () {
            this.isSubmitting = true;
            try {
                var { redirect } = await bindCurrentAccount();
                window.location.href = redirect;
            } catch (ex) {
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        bindOtherAccount () {
            var path = utils.route.generateUrl(
                '/passport/login',
                {
                    returnUrl: this.provisionViewModel.returnUrl,
                    provision: this.provisionViewModel.scheme
                });
            this.$router.push(path);
        },
        createAccount () {
            var path = utils.route.generateUrl(
                '/passport/register',
                {
                    returnUrl: this.provisionViewModel.returnUrl,
                    provision: this.provisionViewModel.scheme
                });
            this.$router.push(path);
        }
    },
    async created () {
        this.isLoading = true;

        // 获取视图模型，该模型包含登录账号信息
        this.provisionViewModel = await getProvisionViewModel();

        // 如果scheme没有值，那么就需要跳转到登录界面
        if (!this.provisionViewModel.scheme) {
            document.location = '/';
        }

        this.isLoading = false;
    }
};
</script>
